import styles from './styles.module.scss';
import responsiveStyles from './responsiveStyles.module.scss';
import { Wrapper } from '../reusable';
import { IsMobile } from '../layout';

function LoaBanner() {
  return (
    <div className={`${styles.banner} ${responsiveStyles.banner}`}>
      <Wrapper>
        <div
          className={`${styles.bannerInner} ${responsiveStyles.bannerInner}`}
        >
          {IsMobile() ? (
            <h1>
              Continue Your <br />
              NO OBLIGATION <br />
              FREE CHECK...
            </h1>
          ) : (
            <>
              <h1>
                Continue Your <br />
                NO OBLIGATION - FREE CHECK
              </h1>
              <p>
                You could be owed £1000's in unclaimed mis-sold car finance.
              </p>
            </>
          )}{' '}
        </div>
      </Wrapper>
    </div>
  );
}

export default LoaBanner;
