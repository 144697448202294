import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRouter from './router';
import { ClaimProvider } from './claimProvider';

function App() {
  return (
    <ClaimProvider>
      <div className='App'>
        <AppRouter />
      </div>
    </ClaimProvider>
  );
}

export default App;
