import styles from './styles.module.scss';
import responsiveStyles from './responsiveStyles.module.scss';
import { Wrapper } from '../reusable';
import { IsMobile } from '../layout';

function IdUploadBanner() {
  return (
    <div className={`${styles.banner} ${responsiveStyles.banner}`}>
      <Wrapper>
        <div
          className={`${styles.bannerInner} ${responsiveStyles.bannerInner}`}
        >
          {IsMobile() ? (
            <h1>Upload Your Proof of Identification</h1>
          ) : (
            <>
              <h1>Upload Your Proof of Identification</h1>
              <p>
                You could be owed £1000's in unclaimed mis-sold car finance.
              </p>
            </>
          )}{' '}
        </div>
      </Wrapper>
    </div>
  );
}

export default IdUploadBanner;
