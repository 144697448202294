import styles from './styles.module.scss';
import responsiveStyles from './responsiveStyles.module.scss';
import { Wrapper } from '../reusable';
import { IsMobile } from '../layout';

import thumb from '../../../src/assets/thumb.svg';
import money from '../../../src/assets/money.svg';
import shield from '../../../src/assets/shield.svg';

function ConversionSteps() {
  return (
    <div className={styles.steps}>
      <Wrapper>
        <p>Make A Claim</p>
        <h2>Three Easy Steps</h2>
        <div className={styles.stepsContainer}>
          <div>
            <img src={thumb} />
            <p>
              <span>1</span>Submit your claim
            </p>
          </div>
          <div>
            <img src={shield} />
            <p>
              <span>2</span>Qualification Approved
            </p>
          </div>
          <div>
            <img src={money} />
            <p>
              <span>3</span>Receive Compensation
            </p>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default ConversionSteps;
