import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import responsiveStyles from './responsive-styles.module.scss';
import logo from '../../../assets/logo.png';
import { Wrapper } from '../../reusable';
import { OriginalFooter } from './footerTypes';

function Footer() {
  const [footerType, setFooterType] = useState<String>('');

  return <OriginalFooter />;
}

export default Footer;
