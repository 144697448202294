import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollToTop } from './components/layout/scrollTop/scrollToTop';

import Main from './containers/main';
import LoaSign from './containers/loaSign';
import IdUpload from './containers/idUpload';

export default function AppRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Main />}>
          <Route path='/' element={<LoaSign />}>
            <Route path=':token' element={<LoaSign />} />
          </Route>
          <Route path='/upload-id' element={<IdUpload />}>
            <Route path=':token' element={<IdUpload />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}
