import styles from '../globals/pages.module.scss';
import pageStyles from './styles.module.scss';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Loading } from 'notiflix';
import { Report } from 'notiflix/build/notiflix-report-aio';

import { Wrapper } from '../components/reusable';
import { apiErrors } from '../helpers/apiErrors';
import { SignaturePad } from '../components/reusable/sigPad';
import Previewer from '../components/reusable/previewer';
import { Checkbox } from '../components/reusable/checkbox';
import LoaBanner from '../components/loaBanner';
import { IsMobile } from '../components/layout';
import ConversionSteps from '../components/conversionSteps';
import ConversionPoints from '../components/conversionPoints';
import { useUpdateClaimData } from '../claimProvider';

import { browserName, osName, deviceDetect, getUA } from 'react-device-detect';
interface cb {
  field: string;
  value: string;
}

function LoaSign() {
  const [claimData, setClaimData] = useState<any>();
  const [documentUrls, setDocumentUrls] = useState<any>();
  const [retrievedClaim, setRetrievedClaim] = useState(false);
  const [agreed, setAgreed] = useState('');
  const { token } = useParams();
  const updateClaim = useUpdateClaimData();

  useEffect(() => {
    console.log(token);
    if (!retrievedClaim) {
      Loading.standard('Loading...');
      axios
        .post(`${process.env.REACT_APP_API_REDEEM_TOKEN}`, { token })
        .then((response) => {
          const networkData = response.data;
          console.log('networkData:', networkData);
          const signatureData = networkData.signature_data;

          if (signatureData != null) {
            setClaimData(signatureData);
            updateClaim(signatureData);
            setDocumentUrls(networkData.urls);
          }
          Loading.remove();
        })
        .catch((e) => {
          console.log('error:', e);
          if (e.response) {
            const serverError = e.response.data;
            Loading.remove();
            apiErrors(serverError);
          } else {
            apiErrors('unknown');
          }
        });
    }
  }, []);

  const completeClaim = () => {
    console.log('documents signed....');
    Report.success(
      'Documents Signed',
      'Thank you for signing all your documents. We will update you on your claim in due course.',
      'Okay',
      () => {
        window.location.href = 'https://google.com';
      }
    );
  };

  const saveCheckbox = (dataToSave: cb) => {
    setAgreed(dataToSave.value);
    return { success: true, error: '' };
  };

  return (
    <div>
      <LoaBanner />
      <ConversionPoints />
      <div className={styles.pageContent}>
        <div>
          <Wrapper>
            <div className={styles.textWrapper}>
              <div>
                <h2>What am I Signing?</h2>
                <p>
                  I agree that I have read, understand and accept the Olton
                  Alexander Ltd{' '}
                  <a target='__blank' href='/documents/OA-tob.pdf'>
                    terms of business
                  </a>{' '}
                  and that the check is free. I also agree to instruct Olton
                  Alexander to send a data subject access request (DSAR) to find
                  out if my PCP or HP agreements were mis-sold.
                  <br />
                  <br />
                  We will carry out a soft credit check to validate your case as
                  well as verify your identity and prevent money laundering.
                  This will not impact your credit rating in any way and only
                  data relevant to Vehicle finance will be gathered from your
                  file.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </Wrapper>
        </div>

        <Wrapper>
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Checkbox
              saveValue={saveCheckbox}
              fieldName='termsAgreed'
              label={
                'I agree that I have read, understand and accept the Olton Alexander Ltd terms of business and that the check is free. I also agree to instruct Olton Alexander to send a data subject access request (DSAR) to find out if my PCP or HP agreements were mis-sold.'
              }
            />
            <br />
            <SignaturePad
              additionalAction={completeClaim}
              extraPayload={{
                type: 'dsarSign',
                browser: browserName,
                device: osName,
              }}
            />
          </div>
        </Wrapper>
        <div>
          <Wrapper>
            <div className={styles.textWrapper}>
              <div>
                <p>Your document pack includes:</p>
                <ul>
                  <li>
                    OA Terms of Business
                    <a target='__blank' href={documentUrls?.termsUrl}>
                      Preview
                    </a>
                  </li>
                  <li>
                    Letter of Authority
                    <a target='__blank' href={documentUrls?.loaUrl}>
                      Preview
                    </a>
                  </li>
                  <li>
                    Data Subject Access Request
                    <a target='__blank' href={documentUrls?.dsarUrl}>
                      Preview
                    </a>
                  </li>
                  <li>
                    Financial Coduct Authority Declaration
                    <a target='__blank' href={documentUrls?.fcaUrl}>
                      Preview
                    </a>
                  </li>
                </ul>
                <p>
                  Once these actions are completed, we will be in touch with an
                  update on your claim.
                </p>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
      <ConversionSteps />
    </div>
  );
}

export default LoaSign;
