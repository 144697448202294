
import { Report } from "notiflix/build/notiflix-report-aio";
import { Loading } from "notiflix";
import axios from "axios";

interface uploadInterface {
    id: string;
    files: any;
}


export const uploadDataToServer = async (claimObject: any) => {

    console.log("Uploading DATA. ");
    Loading.standard("Uploading DATA.");

    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_UPLOAD_DATA_URL}`,
            data: claimObject,
            // headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("DATA UPLOAD RESPONSE: ", response.data);
        Loading.remove();
        return response.data

    } catch (error: any) {
        console.log("ERROR IS : ", error);
        Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
        return { success: false }

    }
}

export const uploadFileToServer = async (type: string, token: string, files: any) => {

    Loading.standard("Uploading file.");
    const formData = new FormData();
    formData.append("token", token);
    formData.append("type", type);
    // formData.append("file", fileToUpload, "file");
    files.forEach((fileToAttach: any) => {
        formData.append(fileToAttach.fileInputName, fileToAttach.file)
    })
    console.log(formData);

    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_UPLOAD_FILES_URL}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("FILE UPLOAD RESPONSE: ", response.data);
        Loading.remove();
        return response?.data

    } catch (error: any) {
        Loading.remove();

        console.log("ERROR IS : ", error);
        if (error.message === "No file provided") {
            Report.failure("No file selected", "Please add a file and try again.", "okay");
        } else if (error.message === "Extension not accepted") {
            Report.failure("Incorrect file type", "Please check the file type and try again.", "okay");
        } else if (error.message === "File exceeds 10MB") {
            Report.failure("File exceeds 10MB", "Please check the file and try again.", "okay");
        } else {
            Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
        }
    }
    return true
}