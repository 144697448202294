import styles from '../globals/pages.module.scss';
import pageStyles from './styles.module.scss';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Loading } from 'notiflix';
import { Report } from 'notiflix/build/notiflix-report-aio';

import { InputFile, SubmitButton, Wrapper } from '../components/reusable';
import { apiErrors } from '../helpers/apiErrors';
import { SignaturePad } from '../components/reusable/sigPad';
import Previewer from '../components/reusable/previewer';
import { Checkbox } from '../components/reusable/checkbox';
import LoaBanner from '../components/loaBanner';
import { IsMobile } from '../components/layout';
import ConversionSteps from '../components/conversionSteps';
import ConversionPoints from '../components/conversionPoints';
import { useUpdateClaimData } from '../claimProvider';

import { browserName, osName, deviceDetect, getUA } from 'react-device-detect';
import IdUploadBanner from '../components/idUploadBanner';
import { uploadFileToServer } from '../helpers';
interface cb {
  field: string;
  value: string;
}

function IdUpload() {
  const [claimData, setClaimData] = useState<any>();
  const [documentUrls, setDocumentUrls] = useState<any>();
  const [retrievedClaim, setRetrievedClaim] = useState(false);
  const [attachmentsArray, setAttachmentsArray] = useState<Array<any>>([]);
  const { token } = useParams();
  const updateClaim = useUpdateClaimData();

  useEffect(() => {
    console.log(token);
    if (!retrievedClaim) {
      Loading.standard('Loading...');
      axios
        .post(`${process.env.REACT_APP_API_ID_REDEEM_TOKEN}`, { token })
        .then((response) => {
          const networkData = response.data;
          console.log('networkData:', networkData);
          const signatureData = networkData.claim_data;
          if (signatureData != null) {
            setClaimData(signatureData);
            updateClaim(signatureData);
            setDocumentUrls(networkData.urls);
          }
          Loading.remove();
        })
        .catch((e) => {
          console.log('error:', e);
          if (e.response) {
            const serverError = e.response.data;
            Loading.remove();
            apiErrors(serverError);
          } else {
            apiErrors('unknown');
          }
        });
    }
  }, []);

  const completeClaim = () => {
    console.log('documents signed....');
    Report.success(
      'ID Uploaded',
      'Thank you for uploading your ID. We will update you on your claim in due course.',
      'Okay',
      () => {
        window.location.href = 'https://missold-finance.co.uk';
      }
    );
  };

  const submitFile = async () => {
    if (token) {
      const uploaded = await uploadFileToServer(
        'poid',
        token,
        attachmentsArray
      );
      if (uploaded == 'SUCCESS') completeClaim();
    }
  };

  const attachFile = (attachmentData: any) => {
    if (attachmentData != null) {
      setAttachmentsArray([attachmentData]);
    }
    // { fileInputName, filename: file.name, file, fileType: file.type }
  };

  return (
    <div>
      <IdUploadBanner />
      <div className={styles.pageContent}>
        <div>
          <Wrapper>
            <div className={styles.textWrapper}>
              <div>
                <h2>Why am I providing my ID?</h2>
                <p>
                  Your finance provider has stipulated they require Olton
                  Alexander Ltd to produce a copy of your identification. This
                  is critical in confirming our instruction to act on your
                  behalf and subsuquently forcing your finance provider to
                  respond to our data subject access request (DSAR). <br />
                  <br />
                  Your Identification document will be transmitted to Olten
                  Alexander via a secure tunnel and be stored in our secure
                  cloud server. <br />
                  <br />
                  Your identification document can be any goverment issue form
                  of identification, including an up to date passport, driving
                  license or ID card.
                  <br />
                  <br />
                  The check continues to be 100% free along with Olten Alexander
                  still being bound by{' '}
                  <a target='__blank' href='/documents/OA-tob.pdf'>
                    terms of business
                  </a>
                  .
                  <br />
                </p>
              </div>
            </div>
          </Wrapper>
        </div>

        <Wrapper>
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <InputFile
              fileInputName={'ID upload'}
              attachFileToForm={attachFile}
              niceName={'Identification Uplaod'}
              text={''}
            />
            <SubmitButton
              text={'Submit ID'}
              btnType={'primary'}
              onClick={() => {
                submitFile();
              }}
            />
          </div>
        </Wrapper>
      </div>
      <ConversionSteps />
    </div>
  );
}

export default IdUpload;
