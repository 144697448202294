import styles from './styles.module.scss';
import responsiveStyles from './responsive-styles.module.scss';
import logo from '../../../assets/logo.png';
import { Wrapper } from '../../../../reusable';

export function OriginalFooter() {
  return (
    <div className={`${styles.footer} ${responsiveStyles.footer}`}>
      <Wrapper>
        <div
          className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}
        >
          {/* <img src={logo} /> */}
          <p className={styles.disclaimer}>
            This site is owned and operated by Olton Alexander Limited which is
            registered in England and Wales. Registered number: 07652082.
            Registered office: Calcutt Court, Calcutt, Swindon, England, SN6
            6JR. Olton Alexander Limited is authorised and regulated by the
            Financial Conduct Authority in respect of regulated claims
            management activity. FRN: 833941. Information Commissioner's Office
            registration number ZB559651.
          </p>
          <p>
            Mis-sold PCP Finance information Links:
            <br />
            <br />
            <a
              target='__blank'
              href='https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-models'
            >
              https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-models
            </a>
            <br />
            <br />
            <a
              target='__blank'
              href='https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf'
            >
              https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
            </a>
          </p>
          <p>
            <a target='__blank' href='/documents/privacy-policy.pdf'>
              Privacy Notice
            </a>
          </p>
        </div>
      </Wrapper>
    </div>
  );
}
