import styles from './styles.module.scss';
import responsiveStyles from './responsiveStyles.module.scss';
import { Wrapper } from '../reusable';
import { IsMobile } from '../layout';

import nowinnofee from '../../../src/assets/nowinnofee.svg';
import expert from '../../../src/assets/expert.svg';
import execellent from '../../../src/assets/excellent.svg';
import asses from '../../../src/assets/free-assesmenet.svg';

function ConversionPoints() {
  return (
    <div className={styles.points}>
      <Wrapper>
        <div className={styles.innerPoints}>
          <div>
            <img src={nowinnofee} />
          </div>
          <div>
            <img src={expert} />
          </div>
          <div>
            <img src={execellent} />
          </div>
          <div>
            <img src={asses} />
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default ConversionPoints;
