import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { OriginalHeader } from './headerTypes';

function Header() {
  const [headerType, setHeaderType] = useState<String>('');
  const { token } = useParams();

  return <OriginalHeader />;
}

export default Header;
